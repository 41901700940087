# Uses the csOnHover directive
# Hover behavior can be styled using .cs-on-hover-active class

@app.directive 'csImageHover', ->
  restrict: 'E'
  scope: {}
  replace: true
  controller: ->

    @aspectClass = 'aspect-' + @imageAspect.replace(':', '-')

    this

  controllerAs: 'csImageHover'
  bindToController: {
    image: '='
    imageAspect: '@'
    likesCount: '='
    hasVideo: '='
    loadImageOnEnterViewport: '='
    moreText: '='
    featuredTagText: '='
    altText: '='
    longdesc: '='
  }

  templateUrl: "/assets/templates/directives/csImageHover/csImageHover.html"
