@app.controller 'componentController', ($scope, ComponentService, $state, $rootScope) ->

  getComponent = =>
    ComponentService.show {id: @componentId}, ((data) =>
      @type = data.componentType
      @name = data.name
      @meta = data.meta
      @slug = data.slug
      @size = data.meta.size
      @theme = data.meta.theme
    ), ((error) =>
      @showNewButton = true
    )

  $scope.$watch 'component.componentId', (newValue, oldValue) =>
    if newValue
      unless @parentType && @parentId
        getComponent()

  @sizeClass = (size) ->
    "component-#{size}"

  @paddedClass = (component) =>
    type = component.type
    switch type
      when 'header'
        'padded padded-large'
      when 'matrix'
        if component.meta && _.contains(['Media', 'List', 'Circle', 'Header', 'Popup', 'Card'], component.meta.itemTypeName)
          'padded padded-large'
      when 'matrix-collapsible'
        if component.meta && _.contains(['Media', 'List', 'Circle', 'Header', 'Popup', 'Card'], component.meta.itemTypeName)
          'padded padded-large'
      when 'matrix-filter'
        if component.meta && _.contains(['Media', 'List', 'Circle', 'Header', 'Popup', 'Card'], component.meta.itemTypeName)
          'padded padded-large'
      when 'feed'
        if component.meta && _.contains(['Media', 'List', 'Circle', 'Header', 'Popup', 'Card'], component.meta.itemTypeName)
          'padded padded-large'
      when 'media'
        unless component.meta.size == 'full' && component.meta.nav == 'ghost'
          'padded padded-large'
      when 'text-block'
        'padded padded-large'
      when 'pageNav'
        'padded'
      when 'hero'
        if component.meta.items[0].content.heroType == 'collection'
          'padded padded-large'
      when 'olapic'
        'padded'
      when 'jobs'
        'padded padded-large'
      when 'custom'
        @meta.paddedClass

  @typeClass = (type) ->
    "component-#{type}"

  @themeClass = (theme) ->
    "theme-#{theme}"

  @showEditingClass = =>
    if @showMenu
      "component-editing"

  @currentUser = $state.currentUser
  @pageRequiresPremiumClass = =>
    "disable-non-premium" if @pageRequiresPremium

  @currentUser = $state.currentUser
  $rootScope.$on '$stateChangeSuccess', =>
    @currentUser = $state.currentUser
  this

@app.directive 'component', ($state)->
  restrict: 'E'
  scope: {}
  replace: true

  controller: 'componentController'
  controllerAs: 'component'
  bindToController: {
    componentId: '='
    parentType: '='
    parentId: '='
    isAdmin: '='
    type: '='
    name: '='
    meta: '='
    size: '='
    theme: '='
    pageRequiresPremium: '='
    showMenu: '='
    enableHeader: '='
    loadImageOnEnterViewport: '='
    order: '='
    source: '='
    slug: '='
    classDetail: '='
    moveUp: '&'
    moveDown: '&'
  }

  templateUrl: "/assets/templates/directives/component/component.html"
