@app.controller 'favouritesController', ($scope, $rootScope, $timeout, $sce, $state, $http, $location, localStorageService, MetadataService, AlgoliaSearchService) ->
# Service mappings for use in template
  locationType = {
    'my-recipes': 'my-recipes',
    'my-classes': 'my-classes'
  }
  @$state = $state
  @searchService            = AlgoliaSearchService
  @emptyResultsSuggestions  = []
  @emptyResultsForPopularClasses = []
  @activityType             =  _.get(locationType, $location.search().type, 'favourites')
  @noResultMsg              = {
    favourites: {
      title: 'You have no favorites'
      desc: 'But here are some of our most popular recipes'
    }
    'my-recipes': {
      title: "You haven't created any recipes"
      desc: 'Make your first recipe'
    }
    'my-classes': {
      title: 'You have no purchased classes'
      desc: 'But here are some of our most popular classes'
    }
  }
  @currentUserPurchasedClasses = $state.currentUser.purchased_classes
  @is_studio = $state.currentUser?.studio
  @is_premium = $state.currentUser?.premium

  # Controller state
  @setState = =>
    @results                  = []
    @noResults                = false
    @dataLoading              = 0
    @doneLoading              = false
    @lastRequestedPage        = 0


  @getHoverInfo = (activity) ->
    $sce.trustAsHtml("<i class='icon-star-empty'></i> #{activity.likes_count || 0}")

  @getResults = =>
    return @emptyResultsSuggestions if @noResults && (@activityType == 'favourites' || 'my-classes')
    @results

  @getPopularClasses = =>
    return @emptyResultsForPopularClasses if (@activityType == 'favourites' || 'my-classes') && @currentUserPurchasedClasses.length > 0

  $scope.$watch 'gallery.activityType', (newValue, oldValue) ->
    $scope.gallery.setState()
    $scope.gallery.loadSuggestionResults()
    $scope.gallery.loadOnePage()

  # Get a page of data
  @loadOnePage = =>
    if !@doneLoading
      page = @lastRequestedPage + 1
      @lastRequestedPage = page
      @dataLoading += 1

      userToken = localStorageService.get('user.token')
      requestType = @activityType
      headerParams = { 'Authorization': 'Bearer ' + userToken, "x-csrf-token":undefined }
      $http(
        method: 'GET'
        headers: headerParams
        url: "/api/v0/my_activity/#{@activityType}?page=#{page}"
      ).success((data, status, headers, config) =>
        if requestType == @activityType
          @dataLoading -= 1
          if page == 0
            @results = []
            $timeout( ( ->
              $rootScope.$broadcast 'contentChange'
            ), 1000)
          Array::push.apply @results, data
          @noResults = @results.length == 0
          @doneLoading = true if data.length == 0
      )

  # ------------------- startup stuff -------------------
  # Load up some suggested results if the users query set is empty
  @loadSuggestionResults = =>
    @emptyResultsSuggestions = []
    if @activityType != 'my-recipes'
      $timeout ( =>
        userToken = localStorageService.get('user.token')
        headerParams = { 'Authorization': 'Bearer ' + userToken, "x-csrf-token":undefined }
        $http(
          method: 'GET'
          headers: headerParams
          url: "/api/v0/my_activity/no_data_collection?type=#{@activityType}"
        ).success((data, status, headers, config) =>
          @emptyResultsSuggestions = data
          @emptyResultsForPopularClasses = data
        )
      ), 1000

  @loadSuggestionResults()
  @setState()

  MetadataService.set
    title: 'Favorites'
    description: 'Explore favorited recipes from ChefSteps.com'
    keywords: ''
    canonical: "/favorites"

    this
