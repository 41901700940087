@app.service 'SurveyService', ($http, $q, $state, $timeout, localStorageService) ->
  @submit = (activityId, responseId) ->
    $http(
      method: 'POST'
      url: "#{csConfig.chefstepsEndpoint}/api/v0/survey/submit"
      data:
        activity_id: activityId
        response_id: responseId
      headers:
        Authorization: "Bearer #{localStorageService.get('user.token')}"
    )

  this