@app.service 'DigitaldataService', ($rootScope, LocationService, $state) ->

  @dict = {}

  @getPathName = ->
    country = LocationService.getCountry() || 'US'
    pathname = window.location.pathname
    path = if pathname == '/' then 'home' else pathname.slice(1)
    "Chefsteps-#{country}:#{path}"

  @defaultDict =
    page:
      pageInfo:
        brandName: 'Chefsteps'
        country: LocationService.getCountry() || 'US'
        language: 'en'
        pageName: @getPathName()
        url: window.location.href
    user:
      userInfo:
        customerId: $state.currentUser?.id || null
        premium_member: $state.currentUser?.premium_member || null
        studio: $state.currentUser?.studio || null
        purchased_classes: $state.currentUser?.purchased_classes || []

  @reset = ->
    @set {}

  @reload = ->
    @set {
      user:
        userInfo:
          customerId: $state.currentUser?.id
          premium_member: $state.currentUser?.premium_member
          studio: $state.currentUser?.studio
          purchased_classes: $state.currentUser?.purchased_classes || []
    }

  @set = (dict) =>
    @dict = angular.extend({}, @defaultDict, dict)


  # Clear the dictionary when we change states. When leaving a state, if it never set
  # title, warn ourselves in the console. (Can't do this on entering a state because
  # it may not be able to set metadata until after a server response.)
  $rootScope.$on '$stateChangeStart', (event, toState, toParams, fromState) =>
    @reset()

  this
