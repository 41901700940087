@app.directive 'csBackgroundImage', (csFilepickerMethods, staticRenderService, $window, $rootScope) ->
  restrict: 'A'
  transclude: true
  scope: {
    csBackgroundImageSrc: '='
    noResize: '='
    applyGreyscale: '='
  }
  link: (scope, element, attrs) ->
    visibleInViewport = false
    loaded = false

    getImageUrl = ->
      # Get element width
      elementWidth = element[0].clientWidth

      # Round it to nearest 50px to limit the number of different image sizes
      finalWidth = Math.ceil(elementWidth / 50.0) * 50

      # Account for Retina displays
      if window.devicePixelRatio >= 2
        imageWidth = finalWidth*2
      else
        imageWidth = finalWidth
      imageUrl = csFilepickerMethods.convert scope.csBackgroundImageSrc,
        if ! scope.noResize then {width: imageWidth} else {}
      imageUrl

    scope.setBackground = ->
      loaded = true
      scope.imageUrl = getImageUrl()
      scope.thumbnailImageClass = 'thumbnail-image-hidden'
      $rootScope.$safeApply()

    scope.$watch 'csBackgroundImageSrc', (newValue) ->
      unless staticRenderService.get()
        scope.thumbnailImageUrl = csFilepickerMethods.convert(newValue, {width: 20})
      if newValue && visibleInViewport
        scope.imageUrl = getImageUrl()

    scope.onEnterViewport = ->
      visibleInViewport = true
      if !loaded
        scope.imageUrl = getImageUrl()

    $rootScope.$on '$stateChangeSuccess', ->
      if !loaded
        scope.imageUrl = getImageUrl()

  templateUrl: "/assets/templates/directives/csBackgroundImage/csBackgroundImage.html"
