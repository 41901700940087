@app.directive 'csradiostateparam', ($filter, $state, csConfig) ->
  restrict: 'E',
  scope: { paramName: "@", choices: "=", tabkey: "="},
  templateUrl: "/assets/templates/directives/csRadioStateParam/csRadioStateParam.html"

  link: ($scope) ->
    $scope.$state = $state

    $scope.goState = (choice) ->
      newState = angular.extend({}, $state.params)
      newState[$scope.paramName] = choice
      #In /gallery page, On page loads we need to query 'sort=promoted' as default.
      #When user select any filter in gallery page, 'sort=newest' or existing selected sort option will be invoked.
      if newState['sort'] == 'featured'
        newState['sort'] = 'newest'
      $state.go '.', newState



