@app.directive 'multiSubscriptionButton', ($state, AnalyticsService, utilities) ->
  restrict: 'E'
  scope: {}
  bindToController: {
    firstLabel: '='
    secondLabel: '='
    firstPlanId: '='
    secondPlanId: '='
    firstDesc: '='
    secondDesc: '='
    alreadySubscribedText: '='
    theme: '='
    firstIsGift: '='
    secondIsGift: '='
  }
  controllerAs: 'multiSubscriptionButton'
  controller: ($scope, subscriptionService, ModalService, localStorageService) ->
    onSubscribeError = (errorType) ->
      AnalyticsService.track 'Subscription Button Error', {errorType: errorType}

      $scope.showError = true
      $scope.errorType = errorType

    $scope.onDismissErrorClick = ->
      $scope.showError = false

    $scope.onClick = (planID, isGift) ->
      isGift ||= false
      AnalyticsService.track 'Subscription Button Clicked', {planId: planID, loggedIn: $state.currentUser?}

      if !$state.currentUser
        localStorageService.set('openStudioPassSubscription', {plan_id: planID, is_gift: isGift})
        ModalService.open('login', source: 'subscriptionButton')
      else
        subscriptionService.subscribe(planID, onSubscribeError, isGift)

    openSubscriptionModal = ->
      if $state.currentUser && !$state.currentUser.studio
        localStorage = localStorageService.get('openStudioPassSubscription')
        $scope.onClick(localStorage['plan_id'], localStorage['is_gift']) if _.get(localStorage,'plan_id')
      localStorageService.remove('openStudioPassSubscription')

    openSubscriptionModal()
    @firstDesc = utilities.customSanitize(@firstDesc)
    @secondDesc = utilities.customSanitize(@secondDesc)
    this

  templateUrl: '/assets/templates/components/customButton/multiSubscriptionButton/multiSubscriptionButton.html'
