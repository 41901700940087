@app.directive 'matrixItemCard', (matrixItemService, mobileDetectionService) ->
  restrict: 'E'
  scope: {}
  controller: ->
    @isSubscriptionTag = false
    @isRecipe = matrixItemService.isRecipe(@content.activity_type)
    @showClassTag = matrixItemService.showClassTag(@content)
    @isPurchased = matrixItemService.isPurchased(@content)
    @subscriptionLevel = matrixItemService.subscriptionLevel(@content)
    @isSubscriptionTag = !_.isEmpty(@subscriptionLevel)
    @isMobile = mobileDetectionService.isMobile
    this
  controllerAs: 'matrixItemCard'
  templateUrl: "/assets/templates/components/matrix/matrixItem/matrixItemCard/matrixItemCard.html"
  bindToController: {
    content: '='
    descriptionCharLimit: '='
    showLikes: '='
    loadImageOnEnterViewport: '='
    track: '&'
  }
