@app.directive 'trialCouponSubscriptionButtonForm', ($state, $filter)->
  restrict: 'E'
  scope: {}
  controllerAs: 'trialCouponSubscriptionButtonForm'
  controller: ->
    this

  bindToController: {
    content: '='
  }
  templateUrl: "/assets/templates/components/customButton/trialCouponSubscriptionButton/trialCouponSubscriptionButtonForm.html"
