@app.controller 'NavController', ($rootScope, ModalService, $state, $scope, $window, $timeout, navService, csConfig) ->
  @menuItemsTemplate = "/assets/templates/directives/nav/navItems.html"
  @isNavVisible = false
  @isNavFixed = false
  previousIsNavVisible = false
  previousY = 0
  @currentRoute = $state.current.name
  @navState = []
  @forcedHidden = false
  @isFlagShown = false
  @shopifyEndpoint = "#{csConfig.shopifyEndpoint}"
  @spreeEndpoint = "#{csConfig.spreeEndpoint}"
  @catalogEndpoint = "#{csConfig.catalogEndpoint}"
  @isInternationalLive = csConfig.isInternationalLive
  @isStudioLive = csConfig.isStudioLive
  @showFlagMenuInHeader = true #$state.productGroupsData.product_groups.joule.products.length > 0
  $scope.highlightedMenu = {}
  $scope.navMainMenu = []
  $scope.navMenus = {}

  navService.getMenus()
    .then (res) ->
      $scope.navMenus = res
      $scope.navMainMenu = _.get(res, '0', [])
      $scope.highlightedMenu = $scope.navMainMenu[0]
    .catch (error) ->
      console.log('Error')


  $rootScope.$on 'flag.stateChange', =>
    @isFlagShown = true

  @showFavLogo = (type) =>
    return false unless $state.currentUser?.studio || $state.currentUser?.premium || !_.isEmpty($state.currentUser?.purchased_classes)
    if @isNavFixed || !_.contains @navState, 'nav-ghost'
      if $state.current.views.main.data?.favouritePage
        type == 'on'
      else
        type == 'off'
    else
      if $state.current.views.main.data?.favouritePage
        type == 'white-on'
      else
        type == 'white-off'

  @handleClickSref = ->
    # make sure nav closes any time a $state change link is clicked
    # this handles cases even when the state doesn't end up changing
    @showMenu = false

  checkScrollPosition = (y) =>
    if @navState && _.contains @navState, 'nav-sticky'
      @isNavFixed = true
      if y >= 50
        @navGhost = false
        $rootScope.$safeApply()
      else
        @navGhost = true
        $rootScope.$safeApply()

    # Improve transition from fixed nav to ghost nav by hiding the sticky nav
    # before the chose nav scrolls into view
    else if @navState && _.contains @navState, 'nav-ghost'
      if y > 450
        @isNavFixed = true
        @isNavVisible = y < previousY # you moved up
        $rootScope.$safeApply() if @isNavVisible != previousIsNavVisible
        previousIsNavVisible = @isNavVisible
        previousY = y
      if y < 450
        @isNavVisible = false
        $rootScope.$safeApply()
      if y <= 200
        @isNavFixed = false
        $rootScope.$safeApply() if @isNavVisible != @previousIsNavVisible

    # If a flag is present, don't liet the fixed nav scroll to the top
    else if @isFlagShown = true
      if y > 200
        @isNavFixed = true
        @isNavVisible = y < previousY # you moved up
        $rootScope.$safeApply() if @isNavVisible != previousIsNavVisible
        previousIsNavVisible = @isNavVisible
        previousY = y
      if y <= 56
        @isNavVisible = false
        @isNavFixed = false
        $rootScope.$safeApply() if @isNavVisible != @previousIsNavVisible

    # Normal sticky scrolling nav. Replace sticky nav with the absolute
    # nav when scrolled to the top
    else
      if y > 200
        @isNavFixed = true
        @isNavVisible = y < previousY # you moved up
        $rootScope.$safeApply() if @isNavVisible != previousIsNavVisible
        previousIsNavVisible = @isNavVisible
        previousY = y

      if y <= 0
        @isNavVisible = false
        @isNavFixed = false
        $rootScope.$safeApply() if @isNavVisible != @previousIsNavVisible

  angular.element($window).on 'scroll', _.throttle(->
    checkScrollPosition @pageYOffset
  ,200)

  $rootScope.$on '$stateChangeSuccess', =>
    @showMenu = false

  $rootScope.$on 'nav.stateChange', =>
    @navState = navService.navState

  $rootScope.$on 'nav.hideNav', (event, hide) =>
    @forcedHidden = hide

  @login = ->
    @showMenu = false
    # $timout is needed so create a smooth transition between the nav menu closing and the login modal opening
    $timeout (->
      ModalService.open('login', source: 'nav')
    ), 200

  @redirectUrl = (url) ->
    location.assign(url)

  this
