@app.directive 'promptCard', (matrixItemService, mobileDetectionService) ->
  restrict: 'E'
  scope: {}
  controller: ->
    @favSample = _.sample(['eggs-plore', 'peak', 'salty'])
    @favSampleText = {"eggs-plore": "Eggs-plore", "peak": "Take a pea-k", "salty": "Don't be salty."}[@favSample]
    @favEmptySample = _.sample(['crumbs', 'dough'])
    @favEmptySampleText = {"crumbs": "Oh crumbs!", "dough": "Uh-dough."}[@favEmptySample]
    @recSample = _.sample(['chilli', 'juice', 'thyme'])
    @recSampleText = {"chilli": "Spice it up.", "juice": "Stay juicy", "thyme": "It's go thyme."}[@recSample]

    this
  controllerAs: 'promptCard'
  templateUrl: "/assets/templates/components/carousel/promptCard/promptCard.html"
  bindToController: {
    activityCollection: '@'
    imgBlockHeight: '@'
    lessFavorites: '@'
    emptyFavorites: '@'
    lessMyrecipes: '@'
  }
