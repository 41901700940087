@app.directive 'matrixCollapsible', ->
  restrict: 'E'
  scope: {}
  controllerAs: 'matrixCollapsible'
  controller: ($scope, $element) ->

    @collapsed = if $(window).width() < 900 then true else false

    @loadImageOnEnterView = if _.isBoolean(@loadImageOnEnterViewport) then @loadImageOnEnterViewport else true
    @toggleCollapse = ->
      @collapsed = ! @collapsed

    @applyClass = =>
      matrixClass = []
      matrixClass.push "matrix-#{@columns}-col" if @columns
      matrixClass.push "matrix-#{@itemType.toLowerCase()}" if @itemType
      matrixClass.push "matrix-item-numbered" if @showItemNumbers
      matrixClass.join ' '

    @showItemNumbers = if @showItemNumbers == 'true' then true else false

    @getItemCount = =>
      _.filter(@items, (item) -> item.content.title).length

    this

  bindToController: {
    title: '='
    showItemNumbers: '='
    items: '='
    rows: '='
    columns: '='
    itemType: '='
    theme: '='
    itemTypeOptions: '='
    loadImageOnEnterViewport: '='
    descriptionCharLimit: '='
  }

  templateUrl: "/assets/templates/components/matrixCollapsible/matrixCollapsible.html"