@app.directive 'cloneActivity', (ModalService, ActivitiesService, $http, $state)->
  restrict: 'E'
  scope: {},
  controllerAs: 'cloneActivity'
  controller: ($scope) ->
    $scope.loading = false
    $scope.showError = false
    $scope.requestError = false
    $scope.checkboxItems = [
      [
        {
          label: "HeroVisual (youtube, vimeo, hero image)"
          key: "hero_visuals"
        }
      ]
      [
        {
          label: "Description"
          key: "description"
        },
        {
          label: "Byline"
          key: "byline"
        }
      ]
      [
        {
          label: "Ingredients"
          key: "ingredients"
        },
        {
          label: "Equipments"
          key: "equipments"
        }
      ]
      [
        {
          label: "Yield"
          key: "yield"
        },
        {
          label: "Timing"
          key: "timing"
        }
      ]
      [
        {
          label: "Steps"
          key: "steps"
        },
        {
          label: "SEO Description"
          key: "short_description"
        }
      ]
      [
        {
          label: "Activity Type"
          key: "activity_type"
        },
        {
          label: "Tags"
          key: "tags"
        }
      ]
      [
        {
          label: "Studio Pass?"
          key: "studio"
        },
        {
          label: "Premium?"
          key: "premium"
        }
      ]
      [
        {
          label: "Difficulty"
          key: "difficulty"
        },
        {
          label: "Featured Image"
          key: "featured_image_id"
        }
      ]
      [
        {
          label: "Featured Image Tag"
          key: "featured_image_tag"
        }
      ]
      [
        {
          label: "Include In Galleries"
          key: "include_in_gallery"
        }
      ]
    ]

    $scope.submit = ->
      return true if $scope.loading

      if _.isEmpty($scope.cloneActivity.title)
        $scope.showError = true
      else
        $scope.showError = false
        $scope.loading = true
        ActivitiesService.clone($scope.cloneActivity.activity.id, $scope.cloneActivity.title, $scope.cloneActivity.content)
          .then (res) ->
            $scope.loading = false
            slug = _.get(res, 'data.slug')
            if slug
              location.assign('/activities/' + slug)
          .catch (error) ->
            $scope.loading = false
            $scope.requestError = true
            console.error(error)

  bindToController: {
    activity: '='
    content: '='
    title: '='
  }
  templateUrl: "/assets/templates/modals/cloneActivity/cloneActivity.html"
