@app.directive 'loginForm', (ModalService, AnalyticsService, $state, $location, authenticationService, $http, $rootScope, csAdwords, csFacebookConversion, formHelper, localStorageService, csConfig, ssoService)->
  restrict: 'E'
  scope: {}
  controller: ($scope) ->
    @params = ModalService.params()
    @showErrorFor = (fieldName, validationParam) =>
      formHelper.showErrorFor(@form, fieldName, validationParam)

    @close = ->
      ModalService.reject()

    @submit = =>

      loginSuccess = =>
        @showSpinner = false

        # Mixpanel
        properties = ModalService.modal.params
        AnalyticsService.reidentify()
        AnalyticsService.track 'Logged In JS', properties

        if @sso
          @state = $location.search().state
          ssoService.signAndRedirect(@state).then ((response) ->

          ), (error) =>
            @errorMessage = error

        else
          ModalService.resolveSuccess()
          window.location.reload()

      loginError = =>
        @errorMessage = 'We were unable to match the email and password you provided. Please check your entries and try again.'
        @showSpinner = false

      if @form.$valid
        @showSpinner = true

        authenticationService
          .loginWithEmail @form.email.$modelValue, @form.password.$modelValue
          .then loginSuccess, loginError

    @signup = ->
      # Transfer params
      ModalService.open('signup', ModalService.modal.params)

    @recoverPassword = ->
      ModalService.open('recover')

    this

  controllerAs: 'login'
  bindToController: {
    sso: '='
  }
  templateUrl: "/assets/templates/directives/ssoForm/loginForm/loginForm.html"
