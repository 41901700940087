@app.service 'GtagServiceHelper', ($window) ->

  # Shows an error for a form field
  @signup = ->
    $window.gtag 'event', 'conversion', 'send_to': 'AW-10814246762/WeahCMS5nYgDEOqe0aQo'

  @subscribeSP = ->
    $window.gtag 'event', 'conversion', { 'send_to': 'AW-10814246762/7gk_CNOox4gDEOqe0aQo', 'value': 48.0, 'currency': 'USD' }
  
  @subscribeSPGift = ->
    $window.gtag 'event', 'conversion', { 'send_to': 'AW-10814246762/_Wm6CLWqnYgDEOqe0aQo', 'value': 48.0, 'currency': 'USD' }

  @purchaseClass = (price) ->
    $window.gtag 'event', 'conversion', { 'send_to': 'AW-10814246762/ZM1lCIS_x4gDEOqe0aQo', 'value': price, 'currency': 'USD' }

  @applyCoupon = ->
    $window.gtag 'event', 'conversion', { 'send_to': 'AW-10814246762/1E3pCNuCzogDEOqe0aQo', 'value': 48.0, 'currency': 'USD' }

  this
