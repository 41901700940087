@app.directive 'csImage', (csFilepickerMethods, $window, $timeout, $rootScope) ->
  restrict: 'E'
  scope: {
    url: '='
    imageType: '='
    aspect: '@'
    loadImageOnEnterViewport: '=',
    limitHeight: '@'
    featuredTagText: '='
  }

  controller: ($scope) ->
    # For cs FetchActivty
    $scope.recalculate = ->
      $scope.calculateWidth()

  link: (scope, element, attrs) ->
    scope.attrs = attrs
    scope.imgOpacity = 0
    parent = element.parent()
    reachedViewport = false

    scope.setReachedViewport = ->
      reachedViewport = true

    scope.getFinalUrl = ->
      if (scope.loadImageOnEnterViewport == true && !reachedViewport)
        ''
      else
        scope.finalUrl

    scope.calculateWidth = ->
      parent.width = $(parent[0]).width()
      # if parent.width == 0
      #   console.log parent
      #   console.log "Parent width is 0px."

      # Round up to nearest 50px - don't want to just use parent.width
      # because that will cause a ton of refetches in a fluid layout during resize.
      finalWidth = Math.ceil(parent.width / 50.0) * 50

      if window.devicePixelRatio >= 2
        scope.finalWidth = finalWidth * 2
      else
        scope.finalWidth = finalWidth

      if scope.aspect
        switch scope.aspect
          # Use aspect container when the container has both height and width defined
          when "container"
            parent.height = $(parent[0]).height()
            scope.finalHeight = scope.finalWidth * parent.height/parent.width
          when "1:1"
            scope.finalHeight = scope.finalWidth
          when "3:2"
            scope.finalHeight = scope.finalWidth * 2 / 3
          when "16:9"
            scope.finalHeight = scope.finalWidth * 9 / 16
          when "3:1"
            scope.finalHeight = scope.finalWidth * 1 / 3
        scope.finalUrl = csFilepickerMethods.convert(scope.url, {w: scope.finalWidth, h: scope.finalHeight})

      else
        scope.finalUrl = csFilepickerMethods.convert(scope.url, {w: scope.finalWidth})

      # Optionally limit the height of the image while maintaining aspect ratio.
      # This is useful in situations where we may want to allow a portait image but at the width of that image, it is overwhelmingly tall.
      # Can't just use max-height because we've got an explicit width so it causes squishing instead of scaling.
      if scope.limitHeight
        img = $($(element).children()[0])
        if img.height() > scope.limitHeight
          h = img.height()
          w = img.width()
          $(element).width(scope.limitHeight * w / h)

      true

    angular.element($window).on 'resize', ->
      scope.calculateWidth()
      $rootScope.$safeApply()

    scope.calculateWidth()

    scope.$watch 'url', (newValue, oldValue) ->
      if newValue
        scope.calculateWidth()

    # Carousel will render and modify the height after the component loaded
    scope.$on 'recalculate-image', ->
      scope.calculateWidth()

  templateUrl: "/assets/templates/directives/csImage/csImage.html"

# Sets opacity to 1 when image is fully loaded
@app.directive 'csImageLoad', (staticRenderService) ->
  restrict: 'A'
  scope: {
    afterLoad: '&'
  }
  link: (scope, element, attrs) ->
    element.addClass 'cs-image-load'

    done = ->
      element.addClass 'loaded'
      scope.afterLoad()

    if staticRenderService.get()
      done()
    else
      element.bind 'load', done

