@app.controller 'ActivityController', (staticRenderService, suggestedRecipeService, $window, $location, $state, $stateParams, AnalyticsService, ActivityService, csConfig, csIngredientService, MetadataService, $scope, $rootScope, $filter, $sce, navService, PageService, ModalService) ->
  @state = $stateParams
  @navService = navService
  @activity = {}
  @commentCount = -1 # for socialButtons directive
  @ingredients = []
  @rescaleFactor = 1
  @isScalingMode = false
  @unit = 'metric'
  @isNellPopupActive = false
  @isStaticRender = staticRenderService.get()
  @collectionTitle = ''
  @editHref = [window.location.protocol, '//', window.location.host, window.location.pathname, '?start_in_edit=true'].join('')
  @context = $state.params['context']
  @slug = $state.params.slug
  @paidLesson = false
  isPrinted = false
  isReportedCooked = false
  lastActiveTime = pageLoadedTime = Date.now()
  @currentUser = $state.currentUser?

  broadcastNavState = =>
    $rootScope.$broadcast 'nav.hideNav', @showCollectionNav
    $rootScope.$broadcast 'collectionNav.openState', @showCollectionNav

  updateNavStateForWidth = =>
    # Set Collection nav to be opened
    @showCollectionNav = ($window.innerWidth >= 1250) && $state.params['context']
    broadcastNavState()
  updateNavStateForWidth()

  $scope.$on '$destroy', =>
    @showCollectionNav = false
    broadcastNavState()

  ## TRACKING

  # Keep track of when the activity was loaded
  resetPageLoadedTime = ->
    lastActiveTime = pageLoadedTime = Date.now()

  # Keep track of last time the user was active on the page
  getActiveMinutes = ->
    Math.floor((lastActiveTime - pageLoadedTime) / (1000 * 60.0))

  updateActiveTime = ->
    lastActiveTime = Date.now()
    maybeReportCooked()

  angular.element($window).on 'scroll', _.throttle(updateActiveTime, 10000)

  isProbablyCooked = ->
    isPrinted || (getActiveMinutes() >= 15)

  maybeReportCooked = ->
    return if isReportedCooked
    if isProbablyCooked()
      isReportedCooked = true
      AnalyticsService.track 'Activity Probably Cooked2', getExtendedEventData()

  # Track everything we know about the user engagement on this activity, then reset it
  # in case loading new activity in class
  trackActivityEngagementFinal =  ->
    # No longer tracking because $$ and not all that useful. Keeping Activity Probably Cooked2 though.
    # AnalyticsService.track 'Activity Engagement Final', getExtendedEventData()
    resetPageLoadedTime()
    isPrinted = false

  angular.element($window).on 'unload', ->
    trackActivityEngagementFinal()

    # http://stackoverflow.com/questions/8350215/delay-page-close-with-javascript
    # Without this, a lot of times the mixpanel event doesn't get recorded; request shows as cancelled in network tab
    # Hmm, and even with this it doesn't work in mobile safari. I tried 'pagehide' stuff but didn't have any luck
    # with that either; so now we track this final engagement when we can but also track send an event as soon
    # as we think we've cooked, see maybeReportCooked()
    start = Date.now()
    while ((Date.now() - start) < 250)
      _.noop()

  # various ways of tracking printing; if you google it you'll find out how unreliable they all are
  window.onbeforeprint = ->
    isPrinted = true
    maybeReportCooked()

  if window.matchMedia
    window.matchMedia("print").addListener (mql) ->
      if mql.matches
        # In chrome "matches" never seems to be true. But we've also instrumented the print button in the tools menu so maybe that helps.
        isPrinted = true
        maybeReportCooked()

  isRecipe = =>
    @activity.ingredients?.length > 1

  getEventData = =>
    'context' : $state.params['context'] || 'naked'
    'title' : @activity.title
    'slug' : @state.slug
    'isRecipe' : isRecipe()
    'tags': @activity.tagList
    'paywalled': @paywalled()
    'carousel': !_.isEmpty($location.search().carousel)
    'carouselTitle': $location.search().carousel_title

  getExtendedEventData = ->

    eventData = getEventData()

    angular.extend eventData,
                    printed: isPrinted
                    activeMinutes: getActiveMinutes()
                    probablyCooked: isProbablyCooked()

  ## PRINTING
  @doPrint = ->
    isPrinted = true
    maybeReportCooked()
    window.print()
    false

  @doClone = =>
    ModalService.open('cloneActivity', activity: @activity)

  ## SCALING
  $scope.$on 'rescaleIngredients', (e, factor) =>
    _.each @ingredients, (ingredient) ->
      csIngredientService.rescaleIngredient ingredient, factor

    _.each @steps, (step) ->
      _.each step.ingredients, (ingredient) ->
        csIngredientService.rescaleIngredient ingredient, factor

    @rescaleFactor = factor
    AnalyticsService.track 'Scaling Changed', slug: @state.slug

  $scope.$on 'setUnit', (e, unit) => @unit = unit

  $scope.$on 'toggleScalingMode', (e) =>
    @isScalingMode = !@isScalingMode
    if @isScalingMode
      AnalyticsService.track 'Ingredients Menu Opened', slug: @state.slug
      $scope.$broadcast 'focusOnCsIngredients'

  $scope.$on 'hideNellPopup', =>
    @isNellPopupActive = false

  $scope.$on 'showNellPopup', (e, d) =>
    @isNellPopupActive = true
    @nellContent = d
    $scope.$apply()

  # TODO: I get an error w/o this but it seems wrong, is it worth changing hero.coffee?
  @heroContent =
    image: ''
    title: ''

  ## SUGGESTED RECIPES
  @relatedRecipes = []
  @usedInRecipes = []
  getSuggestedRecipes = (activity) =>
    # base the number of suggested recipes on character count
    suggestedRecipeSlots = _.min [3, _.floor(activity.description.length / 500)]

    # prioritize showing 'used in' recipes
    @usedInRecipes = activity.usedIn[0...suggestedRecipeSlots]
    suggestedRecipeSlots = _.max [0, suggestedRecipeSlots - @usedInRecipes.length]
    suggestedRecipeService.getRecipes(activity, suggestedRecipeSlots).then((result) => @relatedRecipes = result)


  ## JSON-LD
  # Only ChefSteps content that looks like a recipe
  @shouldIncludeJSONLD = ->
    return false if ! @activity?.ingredients?.length > 0
    @activity.chefstepsGenerated

  # Clean up shortcodes; don't do markdown b/c we don't want HTML
  # in the JSON-LD anyhow, I guess leaving markdown alone is preferable.
  renderedText = (x) ->
    return '' if ! x
    $filter('shortcode')(x)

  # Don't even think of changing this unless you've read and understood:
  #   https://developers.google.com/structured-data/rich-snippets/recipes
  #
  # and tested the output against:
  #   https://developers.google.com/structured-data/testing-tool/
  #
  # For the testing, the best way is to render the page locally and paste in the HTML;
  # if you want to test against chefsteps.com, be sure to add ?_escaped_fragment_=
  # to the end of the URL so you get the static render that the google spider sees via brombone/prerender.
  @getJSONLD = ->
    a = @activity

    ingredients = _.map(a.ingredients, (ai) -> ai.title)

    steps = _.chain(a.steps)
      .filter((step) -> (! step.hideNumber) && (step.directions?.length > 0))
      .map((step) -> renderedText step.title + " " + renderedText step.directions)
      .value()

    # Prefer short description; run filters to get rid of shortcodes.
    description = renderedText (a.shortDescription || a.description)

    # Not including totalTime because our time is unformatted, can't reliably convert
    jsonld =
      '@context'          : 'http://schema.org/'
      '@type'             : 'Recipe'
      name                : a.title
      image               : a.image
      recipeYield         : a.yield
      description         : description
      ingredients         : ingredients
      author:
        '@type'     : 'Organization'
        name        : 'ChefSteps'

    $sce.trustAsHtml JSON.stringify _.pick jsonld, (v, k) -> !! v


  loadActivity = (result) =>
    @activity = result

    $window.scroll(0, 0)
    navService.setState('nav-ghost') if $state.currentUser
    AnalyticsService.track 'Activity Viewed', getExtendedEventData()
    getSuggestedRecipes(result)

    @heroContent =
      image: result.heroImage || result.image
      title: result.title
      byline: result.byline
      description: if result.timing then "Estimated time: #{result.timing}" else null
      youtubeId: result.youtubeId
      vimeoId: result.vimeoId
    @heroVisualSubtitle = "Estimated time: #{result.timing}"
    @timing = result.timing
    @yield = result.yield
    @equipment = result.equipment
    @createdByAdmin = result.chefstepsGenerated
    @ingredients = _.map result.ingredients, csIngredientService.toIngredient
    @steps = _.map result.steps, (step) ->
      _.assign step, ingredients: _.map step.ingredients, csIngredientService.toIngredient

    @shortTextDescription = $filter('shortcode')(result.shortDescription || result.description)

    title = result.title
    if isRecipe()
      if @tagListIncludes('sous vide')
        title = title + ' | Sous Vide Recipe'
      else
        title = title + ' | Recipe'

    # Twitter limits image size to 5 MB, and above that silently shows a card with no image.
    # Filepicker doesn't give us an explicit way to limit file size, but these settings bring
    # us way under 5 MB (under 0.5 M for a typical image) so should be fine.
    socialImage = result.image
    if socialImage
      socialImage = socialImage + '/convert?w=1200&quality=90'

    MetadataService.set
      title: title
      description: @shortTextDescription
      keywords: result.tagList
      canonical: "/activities/#{@state.slug}"
      noindex: !result.chefstepsGenerated
      sousVideTargeted: @tagListIncludes('sous vide')
      og:
        title: result.title
        image: socialImage
        description: @shortTextDescription
      twitter:
        image: socialImage

    updateNavStateForWidth()

  # This is factored out in case we ever want to be able to switch out the data without reloading the controller
  # but currently we don't do that, even for classes.
  @fetchActivity = =>
    if @state.version
      activityRequest = ActivityService.get({id: $state.params.slug, version: $state.params.version})
    else
      activityRequest = ActivityService.get id: $state.params.slug

    activityRequest.$promise
      .then (result) ->
        loadActivity(result)
      .catch (e) ->
        errorCode = e.status.toString().charAt(0)
        # Handle 4XX and 5XX error codes
        if errorCode == '4'
          $state.go('404')
        else if errorCode == '5'
          $state.go('404')

  # Initial load
  @fetchActivity()

  if $state.params['context']
    PageService.show {id: $state.params['context']}, (content) =>
      @collectionTitle = content.title
      @paidLesson = content.isClasses

  @tagListIncludes = (tag) =>
    _.includes @activity.tagList, tag

  @showJouleAd = =>
    # Show ads for sous vide recipes
    # Do not show ads for users who have already purchased Joule
    @tagListIncludes('sous vide') && ! $state.currentUser?.joule_purchase_count && ! @paywalled()

  @showEditButton = ->
    return false if _.isEmpty($state.currentUser)

    $state.currentUser?.admin || @activity.creator?.id == $state.currentUser?.id

  @showCopyButton = ->
    return false if _.isEmpty($state.currentUser)

    $state.currentUser?.admin

  isClassPurchased = =>
    if _.isEmpty($state.params['context']) && _.isEmpty(@activity.classes)
      return false

    userPurchasedClasses = _.get($state.currentUser, 'purchased_classes', [])
    if $state.params['context']
      _.includes(userPurchasedClasses, $state.params['context'])
    else if !_.isEmpty(@activity.classes) && @activity.classes.length == 1
      _.includes(userPurchasedClasses, @activity.classes[0])
    else if !_.isEmpty(@activity.classes) && @activity.classes.length > 1
      !_.isEmpty(_.intersection(userPurchasedClasses, @activity.classes))
    else
      false


  @paywalled = =>
    if @activity.skip_paywall || ((@activity.premium || @activity.studio) && isClassPurchased())
      false
    else
      (@activity.premium && ! $state.currentUser?.premium) || (@activity.studio && ! $state.currentUser?.studio)

  @anonymousPaywalled = =>
    _.isEmpty($state.currentUser) && !(@activity.premium || @activity.studio || @activity.skip_paywall)

  @promoPaywallCoupon =
    if moment().isBefore(moment('2023-11-24T00:01:00'))
      'holidayNov'
    else if moment().isBefore(moment('2023-11-28T00:01:00'))
      'blackFriday'
    else if moment().isAfter(moment('2023-12-01T00:00:00')) && moment().isBefore(moment('2024-01-01T00:01:00'))
      'holidayDec'
    else if moment().isAfter(moment('2024-01-01T00:01:00')) && moment().isBefore(moment('2024-01-16T00:01:00'))
      'levelUpJan'
    else
      null

  @promoPaywalled = =>
    !_.isEmpty(@promoPaywallCoupon)

  @showIngredientScalarPaywall = ->
    csConfig.isStudioLive && !$state.currentUser?.premium && !$state.currentUser?.studio

  @toggleCollectionNav = =>
    @showCollectionNav = ! @showCollectionNav
    navService.showCollectionNavPrompt = false
    broadcastNavState()

  # Remove Collection prompt after page is scrolled
  $rootScope.$on 'throttledScroll', ->
    if $window.pageYOffset >= 300
      navService.showCollectionNavPrompt = false if $state.params['context']
      $rootScope.$safeApply()

  # Show the whole activity if it was paywalled and now they login as premium
  $rootScope.$watch @paywalled, (newValue, oldValue) ->
    if oldValue && ! newValue
      $state.reload('main.activity')

  this
