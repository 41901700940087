@app.directive 'carousel', (matrixItemService, mobileDetectionService, AnalyticsService, $timeout) ->
  restrict: 'E'
  link: (scope, element, attrs) ->
    $timeout (->
      $carousel = scope.carousel
      $(".#{$carousel.carouselTypeClass[$carousel.content.itemType]}").owlCarousel($carousel.configOptions)
      scope.$broadcast ('recalculate-image')
      type = _.get($carousel.content, 'itemType', '')
      scope.imgBlockHeight = $(element).find('.cs-image-hover-content').height()
      if $(element).find('.owl-item').length > $carousel.configOptions['items']
        $(element).find('.matrix-media').addClass('right-border-continue')
        if type == 'full carousel'
          maxHeight = _.max(_.map($(element).find('.item-text'), (e) -> $(e).height() ))
          if(maxHeight)
            $(element).find('.item-text').css({'height': maxHeight})
    ), 300

    $(document).on 'click', '.owl-next', ->
      if $(this).hasClass('disabled')
        $(this).parent().parent().removeClass('right-border-continue')
      else
        $(this).parent().parent().addClass('right-border-continue')
      $(this).parent().parent().addClass('left-border-continue')

    $(document).on 'click', '.owl-prev', ->
      if $(this).hasClass('disabled')
        $(this).parent().parent().removeClass('left-border-continue')
      else
        $(this).parent().parent().addClass('left-border-continue')
      $(this).parent().parent().addClass('right-border-continue')

    $(document).on 'click', '.owl-dot', ->
      $(this).parent().parent().removeClass('left-border-continue')
      $(this).parent().parent().removeClass('right-border-continue')
      if $(this).parent().children().first().hasClass('active')
        $(this).parent().parent().addClass('right-border-continue')
      else if $(this).parent().children().last().hasClass('active')
        $(this).parent().parent().addClass('left-border-continue')

  scope: {}
  controller: ->
    @isMobile = mobileDetectionService.isMobile
    @theme ||= 'light'

    options = {
      'simple carousel with description': {
        'mobile': {
          nav: true,
          margin: 20,
          dots: false,
          items: 1,
          slideBy: 1,
          stagePadding: 80
        }
        'desktop': {
          nav: true,
          margin: 20,
          dots: true,
          items: 3,
          slideBy: 3,
          navClass: ["owl-prev button button-circle", "owl-next button button-circle"]
          navText : ['<span class="icon-spanner-down"></span>','<span class="icon-spanner-down"></span>']
        }
      },
      'simple carousel': {
        'mobile': {
          nav: true,
          dots: false,
          items: 1,
          slideBy: 1,
          stagePadding: 80
        }
        'desktop': {
          nav: true,
          margin: 10,
          dots: true,
          items: 5,
          slideBy: 5,
          stagePadding: 40
          navClass: ["owl-prev button button-circle", "owl-next button button-circle"]
          navText : ['<span class="icon-spanner-down"></span>','<span class="icon-spanner-down"></span>']
        }
      },
      'full carousel': {
        'mobile': {
          nav: true,
          margin: 20,
          dots: false,
          items: 1,
          slideBy: 1,
          stagePadding: 80
        }
        'desktop': {
          nav: true,
          margin: 20,
          dots: true,
          items: 3,
          slideBy: 3,
          navClass: ["owl-prev button button-circle", "owl-next button button-circle"]
          navText : ['<span class="icon-spanner-down"></span>','<span class="icon-spanner-down"></span>']
        }
      }
    }

    # once the carousel component reached the viewport
    # then component will load all the images inside the carousel
    @setReachedViewport = ->
      _.map @items, (e) ->
        e['tileImage'] = e['image']

    @carouselTypeClass = {
      'simple carousel with description': 'simple-carousel-with-description'
      'simple carousel': 'simple-carousel'
      'full carousel': 'full-carousel'
    }

    @configOptions = if @isMobile then options[@content.itemType]['mobile'] else options[@content.itemType]['desktop']

    @items = _.get(@content, 'activityGroup.activities', [])
    title = @content.title
    _.map @items, (e, index) ->
      # initially load first 5 images on carousel to avoid the broken image
      if index > 5
        e['tileImage'] = ''
      else
        e['tileImage'] = e['image']
      e['url'] = "#{csConfig.chefstepsEndpoint}/activities/#{e['slug']}?carousel=true"
      e['url'] += "&carousel_title=#{title}" if title

    @showEmpty = @items.length == 0
    if _.get(@content, 'collectionType') == 'Smart'
      @showMore = @items.length > 23
      @showLess = !@showEmpty && @items.length < 5
      @moreUrl = @content.more_url || {"favourites": '/favorites', "my-recipes": '/favorites?type=my-recipes'}[@content.activityCollection]
      @moreText = @content.more_text || {"favourites": 'My Favorites', "my-recipes": 'My Recipes'}[@content.activityCollection]
    else if _.get(@content, 'collectionType') == 'Curated'
      @moreUrl = @content.more_url || '/gallery'
      @moreText = @content.more_text || 'More Recipes'
      @showMore = !_.isEmpty(@moreUrl) && !_.isEmpty(@moreText)

    @lessFavorites = @showLess && @content.activityCollection == "favourites"
    @emptyFavorites = @showEmpty && @content.activityCollection == "favourites"
    @lessMyrecipes = @showLess && @content.activityCollection == "my-recipes"
    @emptyMyrecipes = @showEmpty && @content.activityCollection == "my-recipes"

    @track = (type, item, position) =>
      AnalyticsService.track 'Carousel Item Clicked', { carouselStyle: type, carouselTitle: @content.title, carouselItems: @items.length, carouselOrder: @order+1, activityTitle: item.title, activityPosition: position+1 }

    this
  controllerAs: 'carousel'
  templateUrl: "/assets/templates/components/carousel/carousel.html"
  bindToController: {
    content: '='
    theme: '='
    order: '='
    track: '&'
  }
