@app.directive 'matrix', ->
  restrict: 'E'
  scope: {}
  controllerAs: 'matrix'
  controller: ($scope) ->

    @applyClass = =>
      matrixClass = []
      matrixClass.push "matrix-#{@columns}-col" if @columns

      matrixClass.push "matrix-#{@itemType.toLowerCase()}" if @itemType
      matrixClass.join ' '

    this

  bindToController: {
    items: '='
    rows: '='
    columns: '='
    itemType: '='
    resourceType: '='
    theme: '='
    itemTypeOptions: '='
    descriptionCharLimit: '='
  }

  templateUrl: "/assets/templates/components/matrix/matrix.html"
