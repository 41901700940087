@app.controller 'ComponentFormController', ($scope, ComponentService, authenticationService, $state, $stateParams) ->
  @showComponentOptions = [{value: 'everyone', label: 'Everyone'}, {value: 'non-premium', label: 'Non-Premium'},
                           {value: 'premium', label: 'Premium'}, { value: 'studio', label: 'Studio'},
                           {value: 'non-studio', label: 'Non-Studio'}, { value: 'admin-only', label: 'Edit-Only'},
                           {value: 'non-purchased-&-non-subscribed', label: 'Non-Purchased & Non-Subscribed'}
                           {value:'paid', label: 'Paid (Studio and Premium)'}, {value:'free', label: 'Free'}]
  @localizationType = [{value: 0, label: 'All Countries'}, {value: 1, label: 'Included Countries'}, {value: 2, label: 'Excluded Countries'}]
  @typeOptions = ['heroSlider', 'hero','matrix','fifty-fifty','feed','header', 'text-block', 'matrix-filter', 'matrix-collapsible', 'divider', 'media', 'banner', 'comments', 'pageNav', 'olapic', 'jobs', 'custom', 'claimSubscriptionGift', 'carousel']
  @sizeOptions = ['full','standard','small']
  @themeOptions = ['light','dark','gray','orange', 'light-with-bottom-line']
  @paddedClassOptions = ['','padded padded-large']
  @navOptions = ['ghost']
  @internationalData = $state.internationalData
  @flags = {}
  @applyToCountries ||= 0
  @countries ||= []
  @maxStartDate = ''

  @minEndDateUpdate = (startDate) ->
    @minEndDate = startDate

  @scheduleDatesUpdate = (startDate, endDate) ->
    @minEndDate = startDate
    @maxStartDate = endDate

  # @meta = ComponentService.new()

  @create = =>
    componentParams =
      componentParentType: @parentType
      componentParentId: @parentId
      componentType: @type
      name: @name
      meta: @meta
      applyToCountries: @applyToCountries
      countries: _.keys (_.pick(@flags, (v,k) -> v))
      scheduleStartDate: if @isSchedule && @scheduleStartDate then moment(@scheduleStartDate).format("YYYY-MM-DD") else null
      scheduleEndDate: if @isSchedule && @scheduleEndDate then moment(@scheduleEndDate).format("YYYY-MM-DD") else null
    ComponentService.create {component: componentParams}, ((component) =>
      if @parentType
        $state.go parentState(@parentType), {id: @parentId}
      else
        $state.go 'main.home'
    ), (error) ->
      console.log 'Error while saving: ', error

  @save = =>
    componentParams =
      componentParentType: @parentType
      componentParentId: @parentId
      componentType: @type
      name: @name
      meta: @meta
      position: @position
      applyToCountries: @applyToCountries
      countries: _.keys (_.pick(@flags, (v,k) -> v))
      scheduleStartDate: if @isSchedule && @scheduleStartDate then moment(@scheduleStartDate).format("YYYY-MM-DD") else null
      scheduleEndDate: if @isSchedule && @scheduleEndDate then moment(@scheduleEndDate).format("YYYY-MM-DD") else null
    ComponentService.update {id: $stateParams.id, component: componentParams}, ((component) =>
      @goToParent()
    ), (error) ->
      console.log 'Error while saving: ', error

  @cancel = =>
    @goToParent()

  @updateType = (componentType) =>
    newComponentType = ComponentService.new({type:componentType})
    newItem = ComponentService.newItem(componentType)
    @meta = angular.extend {}, newComponentType.meta, @meta
    if componentType == 'carousel'
      @sizeOptions = ['full']
      component.meta.size = 'full'
    else
      @sizeOptions = ['full','standard','small']
    angular.forEach @meta.items, (item, index) =>
      # If there's already an item from a previous type, keep the values
      if @meta.items[0]
        @meta.items[0] = angular.merge {}, newItem, @meta.items[0]
      else
        @meta.items[0] = newItem

  @clear = =>
    @name = null
    @type = null
    @meta = ComponentService.new().meta

  @delete = =>
    confirmed = confirm 'Are you sure you want to delete this component?'
    if confirmed
      destroySuccess = =>
        @goToParent()
      destroyError = ->
        console.log 'error deleting'
      ComponentService.destroy {id: $stateParams.id}, destroySuccess, destroyError

  @goToParent = =>
    if @parentType
      $state.go parentState(@parentType), {id: @parentId}
    else
      $state.go 'main.home'

  # Saving or cancelling redirect to parent
  parentState = (parentType) ->
    switch parentType
      when 'Page' then 'main.pages'

  @flagIconClass = (country) ->
    if country
      'flag-icon-' + country.toLowerCase()

  @updateCountries = =>
    if @applyToCountries == 0
      @countries = []


  @setupExport = =>
    # Set exportUrl
    exportContent =
      type: @type
      meta: @meta
      applyToCountries: @applyToCountries
      countries: @countries
      isSchedule: @isSchedule
      scheduleStartDate: if @isSchedule && @scheduleStartDate then moment(@scheduleStartDate).format("YYYY-MM-DD") else null
      scheduleEndDate: if @isSchedule && @scheduleEndDate then moment(@scheduleEndDate).format("YYYY-MM-DD") else null

    data = JSON.stringify(exportContent)
    blob = new Blob([ data ], { type : 'application/json' })
    @exportUrl = (window.URL || window.webkitURL).createObjectURL( blob )
    @exportTitle = "component_#{@name}_#{Date.now()}.json"

  @showExperimentUI = false

  $scope.$watch 'component.type', (newValue) =>
    @setupExport()
    if newValue == 'carousel'
      @sizeOptions = ['full']
      component.meta.size = 'full'

  $scope.$watch 'component.importFile', (newValue) =>
    if newValue
      console.log 'NEW VALUE: ', newValue

      @type = newValue.type
      @meta = newValue.meta
      @applyToCountries = newValue.applyToCountries
      @countries = newValue.countries
      @isSchedule = newValue.isSchedule
      @scheduleStartDate = if newValue.isSchedule && newValue.scheduleStartDate then moment(newValue.scheduleStartDate).startOf('day').toDate() else null
      @scheduleEndDate = if newValue.isSchedule && newValue.scheduleEndDate then moment(newValue.scheduleEndDate).startOf('day').toDate() else null
      @minEndDateUpdate(@scheduleStartDate)
      @scheduleDatesUpdate(@scheduleStartDate, @scheduleEndDate)

  $scope.$watch 'component.countries', (newval, oldval) ->
    if newval
      _.map $scope.component.internationalData.countries, (country) ->
        $scope.component.flags[country.code] = _.includes(newval, country.code)

  this

@app.directive 'componentForm', ->
  restrict: 'E'
  scope: {}
  controller: 'ComponentFormController'
  controllerAs: 'component'
  bindToController: {
    parentType: '='
    parentId: '='
    name: '='
    type: '='
    meta: '='
    mode: '='
    applyToCountries: '='
    countries: '='
    isSchedule: '='
    scheduleStartDate: '='
    scheduleEndDate: '='
    minStartDate: '='
    minEndDate: '='
    position: '='
  }
  templateUrl: "/assets/templates/admin/components/form.html"
