@app.service 'CookieService', ($cookies) ->

  # Set domain to the environments top domain so this cookie can be shared by FreshSteps and Spree
  @getChefStepsTLD = ->
    hostname = location.hostname
    domainParts = hostname.split('.')
    if domainParts.length == 1
      hostname
    else if domainParts[0] == 'www'
      domainParts.shift()
      '.'+domainParts.join('.')
    else
      '.'+hostname

  @set = (name, value, expires) ->
    domainName = process.env.NODE_ENV == 'production' ? '.chefsteps.com' : 'localhost'
    options = { domain: domainName, path: '/' }
    if expires?
      options.expires = expires

    $cookies.put(name, value, options)

  @get = (name) ->
    $cookies.get(name)

  this
