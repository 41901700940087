@app.directive 'appleAuthButton', (ModalService, AnalyticsService, $state, $location, authenticationService, $http, $rootScope, csAdwords, csFacebookConversion, formHelper, localStorageService, csConfig, ssoService, $q, $timeout)->
  restrict: 'E'
  scope: {}
  controller: ($scope) ->
    @signIn = ->
      @showSpinner = true
      successService = (response) ->
        @showSpinner = false
        if @sso
          @state = $location.search().state
          ssoService.signAndRedirect(@state)
        else
          if response.newUser
            csAdwords.track(998032928,'77TfCIjjrAgQoIzz2wM')
            csFacebookConversion.track(6014798030226,0.00)

            # Mixpanel
            properties = {source : 'modal', method: 'apple'}
            AnalyticsService.reidentify()
            AnalyticsService.track 'Signed Up JS', properties

            # Google Analytics
            AnalyticsService.track 'Complete', { category: 'Sign Up' }
          if @ftue && response.newUser
            ModalService.resolveSuccess('ftue')
          else
            ModalService.resolveSuccess()

      errorService = (response) ->
        @showSpinner = false
        errorMessage = {
          403: 'Looks like authorization is invalid',
          409: 'This email is already in use with a different sign in option.'
        }[response.status] || 'Something went wrong'
        ModalService.reject()
        $timeout ->
          ModalService.open('login', {authMessage: errorMessage})

      success = (response) ->
        @showSpinner = false
        givenName = _.get(response, 'user.name.firstName', '')
        familyName = _.get(response, 'user.name.lastName', '')
        creds = {
          authorizationCode: response.authorization.code,
          identityToken: response.authorization.id_token,
          fullName: {
            givenName: givenName,
            familyName: familyName
          }
        }

        authenticationService.csAuthenticateApple(creds, $q.defer())
          .then(successService, errorService)

      error = (err) ->
        @showSpinner = false
        ModalService.reject()
        $timeout ->
          ModalService.open('login')

      AppleID.auth.signIn()
        .then(success, error)

    this
  controllerAs: 'appleAuthButton'
  bindToController: {
    sso: '='
    ftue: '='
  }
  templateUrl: "/assets/templates/directives/ssoForm/appleAuthButton/appleAuthButton.html"
