@app.service 'MetadataService', ($rootScope, $sce) ->

  @dict = {}

  @defaultDict =
    'image'      : 'https://d3awvtnmmsvyot.cloudfront.net/api/file/vCbc5JfR1KUQwWoPLsAL'
    'description': 'ChefSteps is here to make cooking more fun. Get recipes, tips, and videos that show the whys behind the hows for sous vide, grilling, baking, and more.'
    sousVideTargeted: false
    og: {}
    twitter: {}
    titleValue: 'ChefSteps'

  @reset = ->
    @set {}

  @set = (dict) =>
    @dict = angular.extend({}, @defaultDict, dict)

    # If facebook specific stuff isn't provided, take title, image and description from main data
    @dict.og = angular.extend(@dict.og, dict)
    @dict.twitter = angular.extend(@dict.twitter, dict)
    @dict.titleValue = @dict.title + ' | ' + @dict.titleValue if @dict.title

  @getTitle = ->
    title = 'ChefSteps'
    title = @dict.title + ' | ' + title if @dict.title
    $sce.trustAsHtml(title)

  # Clear the dictionary when we change states. When leaving a state, if it never set
  # title, warn ourselves in the console. (Can't do this on entering a state because
  # it may not be able to set metadata until after a server response.)
  $rootScope.$on '$stateChangeStart', (event, toState, toParams, fromState) =>
    if ! fromState?.abstract
      console.log "State \'#{fromState.name}\' never set meta title" unless @dict.title?.length > 0
    @reset()

  @reset()
  this
