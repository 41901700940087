@app.directive 'banner', ->
  restrict: 'E'
  scope: {}
  controllerAs: 'banner'
  controller: ->
    this

  link: (scope, element, attrs) ->

  bindToController: {
    theme: '='
    content: '='
    type: '='
    source: '='
    slug: '='
  }
  templateUrl: "/assets/templates/components/banner/banner.html"
