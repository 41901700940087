@app.controller 'GiftController', ($rootScope, $state, ModalService, localStorageService, $http, AnalyticsService, navService) ->

  @heroContent =
    title: "Lucky You!"
    description: "Someone special gave you the gift of better cooking. Sign up (or sign in) to unlock ChefSteps Premium."
    image: "https://d3awvtnmmsvyot.cloudfront.net/api/file/obQNv5iTXaml6p1MOOV8"
    customButton:
      theme: "solid orange"
      title: "Let's Go!"
      type: 'emit'
      message: 'giftRedeem'

  if $state.currentUser?.premium
    @heroContent.title = "Welcome Back"
    @heroContent.description = "You’re already a Premium member."

  $rootScope.$on 'giftRedeem', =>
    console.log "redeeem"
    @redeem()

  @redeem = ->
    # Already premium?
    if $state.currentUser?.premium
      window.location = "/classes"
      return

    # Signup will resolve immediately if already signed in
    ModalService.open('signup', source: 'giftRedeem').then ->
      userToken = localStorageService.get('user.token')
      headerParams = { 'Authorization': 'Bearer ' + userToken, "x-csrf-token":undefined }

      $http(
        method: 'PUT'
        headers: headerParams
        url: "/api/v0/charges/#{$state.params['gift_token']}/redeem"
      ).success((data, status, headers, config) ->
        AnalyticsService.track 'Gift Redeemed'

        ModalService.open('buyResult', { type: "redeem", description: "ChefSteps Premium", buttonText: "Let's Get Started"}).then ->
          window.location = "/classes"
      ).error((data, status, headers, config) ->
        AnalyticsService.track 'Gift Redeem Error', error: data.error
        ModalService.open('buyResult', { type: "error", message: data.error})
      )

  navService.setState('nav-ghost')

  this
