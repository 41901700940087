@app.directive 'matrixItemMediaForm', (AnalyticsService) ->
  restrict: 'E'
  scope: {}
  controller: ->
    @setItem = (selectedItem, currentItem)->
      angular.forEach currentItem.content, (value, key) ->
        if selectedItem.hasOwnProperty(key)
          currentItem.content[key] = selectedItem[key]

      if currentItem.resourceType == 'Class'
        currentItem.content['price'] = selectedItem['price']
        currentItem.content['plan'] = selectedItem['plan']
        currentItem.content['slug'] = selectedItem['slug']
    this
  controllerAs: 'matrixItemMediaForm'
  templateUrl: "/assets/templates/components/matrix/matrixItem/matrixItemMedia/matrixItemMediaForm.html"
  bindToController: {
    content: '='
    descriptionCharLimit: '='
    showLikes: '='
    loadImageOnEnterViewport: '='
    resourceType: '='
    source: '@'
  }
