@app.directive 'searchTypeahead', (AlgoliaSearchService, localStorageService, ClassesService) ->
  restrict: 'E'
  scope: {
    customMethod: '@'
    type: '='
    select: '&onSelect'
  }
  link: (scope, element, attrs) ->

    scope.search = (query) ->
      if !_.isEmpty(query)
        if scope.type == 'Class'
          ClassesService.search(query).then (result) ->
            scope.searchResults = _.get(result, 'data', [])
        else
          params = {
            difficulty: 'any'
            generator: 'chefsteps'
            published_status: 'all'
            page: '1'
            search_all: query
            attributesToRetrieve: 'title,url,image,likes_count,description,activity_type,premium,studio'
            sort: 'relevance'
          }

          AlgoliaSearchService.search(params).then (data) ->
            scope.searchResults = data
      else
        scope.clear()

    scope.clear = ->
      scope.searchQuery = null
      scope.searchResults = []

  templateUrl: "/assets/templates/directives/searchTypeahead/searchTypeahead.html"
