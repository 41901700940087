@app.controller 'GalleryController', ($scope, $rootScope, AlgoliaSearchService, $timeout, $sce, $state, MetadataService, mobileDetectionService, $window, AnalyticsService) ->
  # Service mappings for use in template
  @$state = $state
  @searchService  = AlgoliaSearchService

  # Controller state
  @results                  = []
  @emptyResultsSuggestions  = []
  @noResults                = false
  @dataLoading              = 0
  @doneLoading              = false
  @lastRequestedPage        = -1
  @filtersCollapsed         = true
  @sortCollapsed            = true
  @isAdmin                  = $state.currentUser?.admin

  # Watch the state and update the gallery.
  # Can't use $stateChangeSuccess with reloadOnSearch: false, but have to
  # have reloadOnSearch: false or we lose focus in the search box when typing.
  $scope.$on '$locationChangeSuccess', (event, newurl, oldurl, newstate, oldstate) ->
    applyFilter()

  # Push an update from the $state.params to ui-router (why doesn't it just watch them?)
  updateState = ->
    $state.go '.', $state.params

  # Template helpers

  adjustSortForSearch = ->
    # If search changes, default sort to relevance - but only on search change
    # because we still want to let them switch to a different sort.
    $state.params['sort'] = if $state.params['search_all']?.length > 0 then 'relevance' else 'newest'
    updateState()

  searchChanged = _.throttle(adjustSortForSearch, 50, {leading: false})

  @searchChanged = ->
    searchChanged()

  @clearSearch = ->
    $state.params['search_all'] = undefined
    adjustSortForSearch()
    $scope.$broadcast 'gallerySearch'

  @clearTags = ->
    $state.params['tag'] = undefined
    updateState()

  @getHoverInfo = (activity) ->
    $sce.trustAsHtml("<i class='icon-star-empty'></i> #{activity.likes_count || 0}")

  @getResults = =>
    return @emptyResultsSuggestions if @noResults
    @results

  @removeTagOnBackspace = (e) =>
    searchQuery = $state.params.search_all
    activeTag = $state.params.tag
    if !searchQuery && activeTag && e.keyCode == 8
      @clearTags()

  $scope.$watch 'gallery.filtersCollapsed', (newValue, oldValue) ->
    if newValue && mobileDetectionService.isDesktop
      $scope.$broadcast 'gallerySearch'

  @toggleSort = (e) =>
    @sortCollapsed = ! @sortCollapsed
    e.stopPropagation()

  angular.element($window).bind 'click', (e) =>
    if !@sortCollapsed
      @sortCollapsed = true

  # Necessary for iOS soft keyboard to prevent submission when form action=''
  # Also blur used to close the soft keyboard
  @submit = (e)->
    e.preventDefault()
    $scope.$broadcast 'focusOff'

  # Update segment.io with changed search. Throttled and trailing edge
  # so if they type we wait until they stop typing. Ignore
  notifySegment =
    _.throttle(
      (->
        filterData = angular.extend({name: 'gallery'}, AlgoliaSearchService.defaultParams, $state.params)
        filterData['defaultFilter'] = _.isEqual(_.omit($state.params, (x) -> _.isUndefined(x)), AlgoliaSearchService.defaultParams)
        AnalyticsService.track 'Gallery Filtered', filterData
      ),
      2000,
      {leading: false}
    )

  # Called whenever an option changes; start over at beginning
  lastTimeout = null
  applyFilter = =>
    @doneLoading        = false
    @lastRequestedPage  = -1
    @filtersCollapsed   = true
    @loadOnePage()
    notifySegment()

  # Get a page of data
  @loadOnePage = =>
    if ! @doneLoading
      page = @lastRequestedPage + 1
      @lastRequestedPage = page
      @dataLoading += 1

      publishedStatus = $state.params['published_status']?.toLowerCase()
      if publishedStatus == 'unpublished'
        unless $state.currentUser?.admin
          $state.params['published_status'] = 'published'
          updateState()

      _.extend($state.params, {attributesToRetrieve: 'objectID,title,url,slug,image,likes_count,description,has_video,activity_type,premium,studio,featured_image_tag,source'})
      rememberParams = _.extend({}, $state.params)

      AlgoliaSearchService.search($state.params, page ).then( ((newResults) =>
        @dataLoading -= 1

        # Results coming back for a search we no longer care about?
        return if ! _.isEqual(rememberParams, $state.params)

        if page == 0
          @results = []
          @noResults = newResults.length == 0

        Array::push.apply @results, newResults
        @doneLoading = true if newResults.length == 0

        # This makes sure infinite scroll, lazy loading etc. recheck themselves after we change
        # searches and therefore resize back down to 0.
        # Delayed because the way angular animation works, the DOM isn't settled down immediately.
        if page == 0
          $timeout( ( ->
            $rootScope.$broadcast 'contentChange'
          ), 1000)

      ), ( =>
        # This will cause the 'no results' results to display
        @dataLoading -= 1
        @doneLoading = true
      ))


  # ------------------- startup stuff -------------------

  # Set up state on load. infiniteScroll directive will load the first page.
  _.defaults($state.params, AlgoliaSearchService.defaultParams)
  $state.go '.', $state.params
  notifySegment()

  # Load up some suggested results if the users query set is empty
  $timeout ( =>
    AlgoliaSearchService.search(sort: 'popular', 0).then (results) =>
      @emptyResultsSuggestions = results
  ), 1000

  MetadataService.set
    title: 'Recipe Gallery'
    description: 'Explore recipes from ChefSteps.com'
    keywords: ''
    canonical: "/gallery"

  this
