@app.directive 'fiftyFifty', (csFilepickerMethods, utilities) ->
  restrict: 'E'
  scope: {}
  controllerAs: 'fiftyFifty'
  controller: ->

    @alignTypeClass = =>
      "fifty-fifty-align-#{@content.alignType}"

    @styleTypeClass = =>
      "fifty-fifty-style-#{@content.styleType}"

    this
  bindToController: {
    content: '='
  }
  templateUrl: "/assets/templates/components/fiftyFifty/fiftyFifty.html"
