@app.directive 'csFetchWidgetShortcode', ->
  restrict: 'E'
  scope: {}
  bindToController:
    'widget': '@'
  controllerAs: 'csFetchWidgetShortcode'
  templateUrl: "/assets/templates/directives/shortcodeOutputs/csFetchWidgetShortcode/csFetchWidgetShortcode.html"

  controller: ->
    if @widget == 'egg-carton-decoder'
      CSWidgets.initEggCartonDecoder elementId: 'egg-carton-decoder-root'
    else if @widget == 'sous-vide-temperature-guide'
      CSWidgets.initTemperatureGuide(elementId: 'sous-vide-temperature-guide-root', template: 'sous-vide-time-and-temperature-guide')

    this
