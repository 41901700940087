@app.directive 'media', ->
  restrict: 'E'
  scope: {}
  controllerAs: 'media'
  controller: ($element, $scope, $timeout) ->
    @getAspect = ->
      return 'portrait' if ($element.children()[0].clientHeight / 9) > ($element.children()[0].clientWidth / 16)
      return 'landscape'

    @isVideoVisible = false

    @play = =>
      @isVideoVisible = true
      $scope.$broadcast 'playVideoClicked'

    this

  link: (scope, element, attrs) ->

  bindToController: {
    theme: '='
    content: '='
    classDetail: '='
  }
  templateUrl: "/assets/templates/components/media/media.html"
