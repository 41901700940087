@app.directive 'trialOrExtendCouponSubscriptionButtonForm', ($state, $filter)->
  restrict: 'E'
  scope: {}
  controllerAs: 'trialOrExtendCouponSubscriptionButtonForm'
  controller: ->
    this

  bindToController: {
    content: '='
  }
  templateUrl: "/assets/templates/components/customButton/trialOrExtendCouponSubscriptionButton/trialOrExtendCouponSubscriptionButtonForm.html"
