@app.directive 'csContent', ($sce, $filter, $compile, $sanitize) ->
  restrict: 'A',
  scope: {
    csContent: '='
    containerData: '='
    trustUnsanitized: '='
    blockTag: '='
    stripOuterParagraph: '@'
  },

  link: (scope, element, attrs) ->
    scope.$watch 'csContent', (input) ->
      # Run markdown and shortcode filters.
      input = $filter('markdown')($filter('shortcode')(input))


      # Sanitize by default unless a parent specially promises this is safe.
      # This lets us do things like embed mailchimp signup forms or random other
      # scripts we might want to test.
      input = $sanitize(input) unless scope.trustUnsanitized

      input = angular.element(input).html() if scope.stripOuterParagraph and input
      conf = {
        "<strong>": "<span class='strong'>",
        "<b>": "<span class='strong'>",
        "<bold>": "<span class='strong'>",
        "<i>": "<span class='italic'>",
        "<I>": "<span class='italic'>",
        "<em>": "<span class='italic'>",
        "</strong>": "</span>",
        "</b>": "</span>",
        "</bold>": "</span>",
        "</i>": "</span>",
        "</I>": "</span>",
        "</em>": "</span>",
        "<h2": "<span class='h2-block'",
        "<h3": "<span class='h3-block'",
        "<h4": "<span class='h4-block'",
        "<h5": "<span class='h5-block'",
        "<h6": "<span class='h6-block'",
        "</h2>": "</span>",
        "</h3>": "</span>",
        "</h4>": "</span>",
        "</h5>": "</span>",
        "</h6>": "</span>"
      }

      divConf = {
        "<p>": "<div class='paragraph-content'>",
        "</p>": "</div>"
      }

      conf = _.merge(conf, divConf) if scope.blockTag == 'div'

      _.each(conf, (value, key, _obj) ->  input = input.replace(new RegExp(key, 'g'), value) )
      element.html input

      # Compile the result in case shortcode or content
      # contained directives.
      $compile(element.contents()) scope
