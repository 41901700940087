@app.directive 'anonymousUserPaywall', ($timeout, ModalService) ->
  restrict: 'E'
  scope: {}
  controllerAs: 'anonymousUserPaywall'
  replace: true
  controller:( $scope) ->

    @joinNow = ->
      ModalService.open('signup',
        source: 'payWallButton'
        extraText: "Sign In is required to view this recipe."
      ).then(
        -> $state.go 'main.activity'
      )

    @login = ->
      @showMenu = false
      # $timout is needed so create a smooth transition between the nav menu closing and the login modal opening
      $timeout (->
        ModalService.open('login', source: 'nav')
      ), 200

    this

  link: (scope, element, attrs) ->

  bindToController: {
    theme: '='
    content: '='
    source: '='
  }
  templateUrl: "/assets/templates/components/banner/anonymousUserPaywall/anonymousUserPaywall.html"
