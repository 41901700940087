@app.service 'ActivitiesService', ($http, $q, $state, $timeout, localStorageService) ->

  @clone = (srcActivityId, title, config) ->
    $http(
      method: 'POST'
      url: "#{csConfig.chefstepsEndpoint}/api/v0/activities/clone"
      data:
        source_activity_id: srcActivityId
        title: title
        config: config
      headers:
        Authorization: "Bearer #{localStorageService.get('user.token')}"
    )

  this