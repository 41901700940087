@app.controller 'GetpremiumController', ($rootScope, $scope, $state, ModalService, localStorageService, $http, AnalyticsService, navService, $location) ->

  codePattern = /// ^
    [-a-z0-9]{3,32}
    $ ///i

  @takeMeHome = ->
    window.location = "/premium"

  @initForm = =>
    @show_waiting = false
    @code = $location.search().premium_code || ''
    @hero_title = 'Get ChefSteps Premium'
    @hero_description = 'Have a code? Enter it here for access to all ChefSteps equipment classes.'
    @show_code = true
    @show_exit = false
    @show_waiting = false
    @error_message = ''
    if $state.currentUser?.premium_member
      @hero_title = "Welcome Back!"
      @hero_description = "You are already a Premium Member."
      @show_code = false
      @show_exit = true

  @redeem = (getpremium_code) =>
    # Already premium?
    if $state.currentUser?.premium
      @takeMeHome()
    else
      @show_waiting = true
      # Signup will resolve immediately if already signed in
      ModalService.open('signup', source: 'getpremiumRedeem').then =>
        userToken = localStorageService.get('user.token')
        headerParams = { 'Authorization': 'Bearer ' + userToken, "x-csrf-token":undefined }
        $http(
          method: 'PUT'
          headers: headerParams
          url: "/api/v0/charges/#{getpremium_code}/redeem"
        ).success((data, status, headers, config) =>
          @show_code = false
          @show_exit = true
          AnalyticsService.track 'Premium Code Redeemed'
          ModalService.open('buyResult', { type: "redeem", description: "ChefSteps Premium", buttonText: "Let's Get Started"})
            .then =>
              @takeMeHome()
            .error((data, status, headers, config) =>
              AnalyticsService.track 'Premium Code Redeem Error', error: data.error
              ModalService.open('buyResult', { type: "error", message: data.error})
                .then =>
                  @initForm()
            )
        )
  @getPremiumRedeem = =>
    if !!@code && @code.match codePattern
      @error_message = null
      my_code = @code
      @redeem(my_code)
    else
      @error_message = 'Please enter a valid code'

  @initForm()

  $rootScope.$on 'getpremiumRedeem', =>
    @getPremiumRedeem()

  $rootScope.$on 'takeMeHome', =>
    @takeMeHome()

  navService.setState('nav-ghost')

  this
