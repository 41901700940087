@app.directive 'redeemSubscription', (GtagServiceHelper) ->
  restrict: 'E'
  scope: {}
  bindToController:
    label: '='
  controllerAs: 'redeemSubscription'
  controller: (subscriptionService) ->
    @coupon = null
    @errorMsg = null
    @config = {
      showDropDown: false
      loading: false
      dropdownClass: 'fa-caret-right'
    }

    @toggleDropDown = =>
      @config.showDropDown = !@config.showDropDown
      @config.dropdownClass = if @config.showDropDown then 'fa-caret-down' else 'fa-caret-right'

    @clearError = =>
      @errorMsg = null

    @redeem = =>
      unless _.isEmpty(@coupon)
        @errorMsg = null
        @config.loading = true
        subscriptionService.redeemCouponForNextSub(@coupon)
          .then (data) =>
            console.log('====success')
            console.log(data)
            GtagServiceHelper.applyCoupon()
            @config.loading = false
            window.location.reload()
          .catch (data) =>
            @config.loading = false
            @errorMsg = _.get(data, 'data.message', 'Invalid Coupon')
      else
        @errorMsg = "Please enter your coupon code"

    this

  templateUrl: "/assets/templates/directives/redeemSubscription/redeemSubscription.html"
