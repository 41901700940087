@app.controller 'carouselFormController', ($http, localStorageService)->
  @itemTypeOptions = ['simple carousel', 'simple carousel with description', 'full carousel']
  @collectionTypeOptions = ['Smart', 'Curated']
  @collectionOptions = {'Smart': [{id: 'favourites', name: 'Favourites'}, {id: 'my-recipes', name: 'My Recipes'}], 'Curated': []}
  headerParams = {
    'Authorization': 'Bearer ' + localStorageService.get('user.token')
  }

  $http({method: 'GET',headers: headerParams,url: "#{csConfig.chefstepsEndpoint}/api/v0/components/carousel_groups"}).then (result) =>
    @collectionOptions['Curated'] = _.get(result, 'data', [])

  @content.collectionType ||= 'Curated'
  this

@app.directive 'carouselForm', ->
  restrict: 'E'
  scope: {}
  controller: 'carouselFormController'
  controllerAs: 'carousel'

  bindToController: {
    items: '='
    itemType: '='
    theme: '='
    content: '='
  }

  templateUrl: "/assets/templates/components/carousel/carouselForm.html"
