@app.directive 'matrixItem', (AnalyticsService) ->
  restrict: 'E'
  scope: {}
  controller: ->
    @itemType = @itemType || 'Media'
    @track = =>
      AnalyticsService.track 'Component Item Clicked', {type: @itemType, title: @content.title, buttonMessage: @content.buttonMessage, url: @content.url, source: @source}

    @onHover = =>
      @hover = ! @hover

    this
  controllerAs: 'matrixItem'
  templateUrl: "/assets/templates/components/matrix/matrixItem/matrixItem.html"
  bindToController: {
    itemType: '='
    resourceType: '='
    descriptionCharLimit: '='
    showLikes: '='
    itemNumber: '='
    loadImageOnEnterViewport: '='
    source: '@'
    content: '='
    theme: '='
  }
