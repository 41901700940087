@app.directive 'csFetchCutsShortcode', ->
  restrict: 'E'
  scope: {}
  controllerAs: 'csFetchCutsShortcode'
  bindToController:
    'slug': '@'
  controller: (ActivityService, $state, $element, $http) ->
    $element.parent().addClass('has-recipe-card')
    @isLoaded = false
    $http.get("/cuts/api/#{@slug}")
      .then (result) =>
        @result = result['data']
        @isLoaded = true
    this
  templateUrl: "/assets/templates/directives/shortcodeOutputs/csFetchCutsShortcode/csFetchCutsShortcode.html"
