@app.directive 'simpleCarousel', (matrixItemService, mobileDetectionService) ->
  restrict: 'E'
  scope: {}
  controller: ->
    @isMobile = mobileDetectionService.isMobile
    @headerLimit = if @isMobile then 30 else 50
    @emptyImage = _.isEmpty(@image)

    this
  controllerAs: 'simpleCarousel'
  templateUrl: "/assets/templates/components/carousel/simpleCarousel/simpleCarousel.html"
  bindToController: {
    title: '='
    image: '='
    url: '='
    description: '='
    alt: '='
    hasVideo: '='
  }
