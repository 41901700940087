# I was initially going to have this be a callbackButton and have message be "callback" as an
# '&' parameter, but it doesn't work well in the component scenario because it passes
# through an '=' parameter for the component metadata. So this is a little bit of a hack
# but will do the job for now.

@app.directive 'emitButton', [ ->
  restrict: 'E'
  scope: {}
  controllerAs: 'emitButton'

  bindToController: {
    label: '='
    message: '='
    theme: '='
    tabIndex: '='
  }

  templateUrl: "/assets/templates/components/customButton/emitButton/emitButton.html"

  controller: ($scope) ->
    @emitMessage = =>
      $scope.$emit @message

    this
]
