@app.service 'CookieConsentService', ($rootScope, CookieService, LocationService) ->
  @consentCookieAccept = 'accept'
  @cookieConsentKey = 'analytics_consent'

  # These countries require explicit consent
  # This list is EU + EAA
  # https://en.wikipedia.org/wiki/European_Economic_Area
  # https://en.wikipedia.org/wiki/Member_state_of_the_European_Union
  @countries_that_require_consent = {
    AT: true,
    BE: true,
    BG: true,
    HR: true,
    CY: true,
    CZ: true,
    DK: true,
    EE: true,
    FI: true,
    FR: true,
    DE: true,
    GR: true,
    HU: true,
    IE: true,
    IT: true,
    LV: true,
    LT: true,
    LU: true,
    MT: true,
    NL: true,
    PL: true,
    PT: true,
    RO: true,
    SK: true,
    SI: true,
    ES: true,
    SE: true,
    GB: true,
    IS: true,
    LI: true,
    GI: true,
    NO: true,
    CH: true
  }

  # Text to show in the banner
  @cookieText = 'Cookies are delicious—but we and our partners also use them to improve your experience and to create marketing experiences relevant to your interests. By clicking "Okay," you agree to the use of cookies as described in our <a href="https://www.breville.com/us/en/legal/cookie-notice.html" target="_blank">Cookie Policy</a>.'
  @buttonText = "Okay"

  LocationService.loadData().then(
    (response) ->
      # Broadcast that the necessary information to determine whether we need to show the cookie consent has now loaded
      # This is needed for services (e.g. AnalyticsService) which call into this service to be informed that they
      # can now check if they are allowed to load analytics.
      # Components which query via ng-if (or similar) do not need to listen to this because they will automatically
      # be informed of any changes on the next digest cycle
      $rootScope.$broadcast('$cookieConsentLoaded')
  )

  # If LocationService has not yet loaded country, then getCountry() will return null
  # In that case we will return false (consent is not needed)
  # We do this because we don't want to show any consent prompts until we know that it is necessary
  @isConsentNeeded = =>
    country = LocationService.getCountry()
    country? && (country of @countries_that_require_consent) && !@isConsentInCookie()

  # This works simiarly to isConsentNeeded
  # If country has not yet loaded then return false
  # This ensures we do not load analytics until we determine that it is OK to do so
  # @canDoAnalytics = =>
  #   country = LocationService.getCountry()
  #   if country?
  #     return !(country of @countries_that_require_consent) || @isConsentInCookie()
    
  #   false

  @canDoAnalytics = ->
    return typeof(analytics) != 'undefined'

  @setConsentAccept = =>
    @setConsentCookie(@consentCookieAccept)
    $rootScope.$broadcast('$cookieConsentAccept')

  @isConsentInCookie = =>
    consentCookie = @getConsentCookie()
    consentCookie? && consentCookie == @consentCookieAccept

  @setConsentCookie = (value) =>
    # expire 20 years from now
    dateNow = new Date()
    days = 365 * 20
    expires = new Date(dateNow.getTime() + days * 24 * 60 * 60 * 1000)

    CookieService.set(@cookieConsentKey, value, expires)

  @getConsentCookie = =>
    CookieService.get(@cookieConsentKey)



  
  this
