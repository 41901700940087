@app.directive 'matrixItemForm', (AnalyticsService) ->
  restrict: 'E'
  scope: {}
  controller: ->
    
    this
  controllerAs: 'matrixItemForm'
  templateUrl: "/assets/templates/components/matrix/matrixItem/matrixItemForm.html"
  bindToController: {
    content: '='
    itemType: '='
    resourceType: '='
  }
