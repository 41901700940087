@app.directive 'simpleCarouselWithDescription', (matrixItemService, mobileDetectionService) ->
  restrict: 'E'
  scope: {}
  controller: ->
    @isMobile = mobileDetectionService.isMobile
    @headerLimit = if @isMobile then 30 else 50
    @emptyImage = _.isEmpty(@image)
    @charLimit = if @isMobile then 50 else 100

    this
  controllerAs: 'simpleCarouselWithDescription'
  templateUrl: "/assets/templates/components/carousel/simpleCarouselWithDescription/simpleCarouselWithDescription.html"
  bindToController: {
    title: '='
    image: '='
    url: '='
    description: '='
    alt: '='
    hasVideo: '='
  }
