@app.controller 'AppController', (AnalyticsService, DigitaldataService, ExperimentService, csConfig, mobileDetectionService, $scope, $rootScope, $state, $window, $timeout, navService, $location, $anchorScroll) ->

  @csConfig = csConfig

#  AnalyticsService.init()
  ExperimentService.init()

  @isMobile = mobileDetectionService.detect()

  @isDesktop = !@isMobile
  @showFlag = false

  @mobileClass = ->
    if @isMobile
      'is-mobile'
    else
      ''

  # Provide a root level throttled scroll to discourage creating a million
  # scroll listeners.
  windowElement = angular.element($window)
  windowElement.on 'scroll', _.throttle(
    -> $rootScope.$broadcast('throttledScroll'),
  100)

  $rootScope.$on 'modals.open', (event, modalType, params) =>
    $timeout ( =>
      @showModals = true
      unless modalType == 'content'
        @modalsClass = 'show-modals'
    ), 100
    @activeModal = modalType
    @modalParams = params


  $rootScope.$on 'modals.close', (event) =>
    @activeModal = null
    # Fade out animation doesn't work without the delay
    $timeout ( =>
      @showModals = false
      @modalsClass = ''
    ), 100

  $rootScope.$on '$stateChangeStart', =>
    @navClass = ''

  $rootScope.$on 'collectionNav.openState', (event, state) =>
    @showCollectionNav = state

  $rootScope.$on '$stateChangeSuccess', =>
    # Allows currentUser to be available as app.currentUser
    @currentUser = $state.currentUser
    # Make sure window scrolls to top of page between state change
    window.scrollTo(0,0)

  $rootScope.$on 'nav.stateChange', =>
    @navClass = navService.navState

  $rootScope.$on 'flag.stateChange', =>
    @showFlag = !@showFlag

  # To make anchor tags work
  # $timeout is needed until we have a reliable way of knowing when all the component contents load
  # Two situations that can cause this not to work well:
  # 1. If the component id is not present when $anchorScroll() is called
  # 2. When all the content above the anchorTag is not fully loaded and there is a change in height after $anchorScroll() is called
  $rootScope.$on '$viewContentLoaded', ->
    DigitaldataService.reload()
    window.digitalData = DigitaldataService.dict
    window.freshpaint.addPageviewProperties({'digitalData': window.digitalData})
    if $location.hash()
      $timeout (->
        $anchorScroll()
      ), 3000
  this
