@app.directive 'collectionNav', (PageService, utilities, $stateParams) ->
  restrict: 'E'
  scope: {}
  replace: true
  bindToController:
    collectionId: '@'
  controllerAs: 'collectionNav'
  controller: ($state) ->

    @slug = $stateParams['context']

    handleSuccess = (data) =>
      @content = data

    handleError = (error) ->
      console.log 'Error getting page: ', error

    if @collectionId
      PageService.show {id: @collectionId}, handleSuccess, handleError

    @showComponent = (component) ->
      return false if _.includes ['media', 'text-block', 'hero', 'banner'], component.componentType

      canShow = utilities.showCountryBasedComponent(@currentUser, component.applyToCountries, component.countries)
      return false if canShow == false

      canShow = utilities.showScheduledComponent(component.scheduleStartDate, component.scheduleEndDate)
      return false if canShow == false

      utilities.maybeShowComponent(@currentUser, component.meta.showTo)

    this

  templateUrl: "/assets/templates/directives/collectionNav/collectionNav.html"
